import { ArticleHeader } from "@components/article/ArticleHeader"
import { defineBlock, EditableText, usePostEditor, usePostMetaEditor, usePostTitleEditor, useTemplate } from "eddev/blocks"
import { useState } from "react"

export const meta: BlockMeta = {
  title: "Article Header",
  icon: "book-alt",
  inserter: false,
  postMetaBlock: {
    postTypes: ["article"],
    fieldName: "articleInfo",
  },
  flags: {
    fullWidth: true,
    selfAnimates: true,
  },
}

export default defineBlock("article/article-header", (props) => {
  const template = useTemplate()

  let chips = props.article?.chips ?? []

  if (env.admin) {
    chips = []
    if (props.collection) {
      chips.push({ label: props.collection.name!, url: undefined })
    }
    if (props.branches?.length! > 0) {
      props.branches?.forEach((item) => chips.push({ label: item?.shortName! || item?.name!, url: undefined }))
    }
    if (props.category?.length! > 0) {
      props.category?.forEach((item) => chips.push({ label: item?.name!, url: undefined }))
    }
  }

  return (
    <ArticleHeader
      mode={template === "single-article" ? "centered" : "editorial"}
      title={env.admin ? <EditableText store={usePostTitleEditor()} as="div" placeholder="Enter article title" /> : props.article?.title}
      subtitle={env.admin ? <EditableText store={usePostMetaEditor("subtitle")} as="div" placeholder="Optional subtitle" /> : props.article?.subtitle ?? ""}
      date={env.admin ? usePostEditor().post?.date : props.article?.date}
      author={props.member!}
      chips={chips}
    />
  )
})
